module NotificationService {
    let app = angular.module("app");

    app.factory("NotificationService", ["$resource", "GlobalApplicationData", ($resource: ng.resource.IResourceService, GlobalApplicationData: any) => {
        let serv = {
            // Aggiunge un nuovo piano. Conterrà solo il titolo e l'UUID
            getNotifications: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-notification-list/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }),
            notificationCount: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-notifications', {

            }, {
                query: {
                     method: 'GET',
                     isArray: false
                }
            }, {
                cancellable: true
            }),
            // Marca la notifica di risposta ad un feedback come letta
            markResponseFeedbackAsRead: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/mark-response-feedback-as-read', {
               
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }),

            // Marca la notifica come letta passandogli il trittico di reference
            markNotificationAsReadByReference: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/mark-notification-as-read-by-reference', {
               
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            }),

            // Marca la notifica come letta passando l'id della notifica
            markNotificationAsRead: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/mark-notification-as-read', {
               
            }, {
                query: {
                     method: 'POST',
                     isArray: false
                }
            })


        };
        return serv;
    }]);
}